import styled from "styled-components";
import { StyledButton } from "components/core/Button/index.style";

const StyledRegisterCTA = styled.div`
  display: flex;
  flex-direction: column;
`;

const GetStartedButton = styled(StyledButton)`
  background: ${(props) => props.theme.accentColor};
  border: 2px solid ${(props) => props.theme.accentColor};
  text-transform: uppercase;
  font-size: 14px;
  line-height: 28px;
  transition: all 300ms;
  height: 48px;

  svg {
    stroke: transparent;
    opacity: 0;
    transform: scale(0);
    width: 0;
  }

  &:hover {
    background: ${(props) => props.theme.accentDarkenColor};
    border: 2px solid ${(props) => props.theme.accentDarkenColor};
    box-shadow: none;

    svg {
      stroke: ${(props) => props.theme.whiteColor};
      opacity: 1;
      transform: scale(1);
      width: 26px;
    }
  }
`;

export { StyledRegisterCTA, GetStartedButton };
