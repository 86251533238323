import { theme } from "styles/theme";
import { CTAColor } from "app-types/color";

const darkColor = "#0A0B0C";
const lightColor = "#FFFFFF";

export const getCTAColor = (ctaColor: CTAColor) => {
  switch (ctaColor) {
    case CTAColor.Yellow:
      return theme["yellowColor"];
    case CTAColor.Green:
      return theme["greenColor"];
    case CTAColor.Black:
      return theme["titleColor"];
    case CTAColor.Primary:
    default:
      return theme["accentColor"];
  }
};

export const getCTADarkenColor = (ctaColor: CTAColor) => {
  switch (ctaColor) {
    case CTAColor.Yellow:
      return theme["yellowDarkenColor"];
    case CTAColor.Green:
      return theme["greenDarkenColor"];
    case CTAColor.Primary:
    default:
      return theme["accentDarkenColor"];
  }
};

export const getRGB = (color: any): [number, number, number] => {
  let test;

  if (color && color.constructor === Array && color.length === 3) {
    return [parseInt(color[0]), parseInt(color[1]), parseInt(color[2])];
  }

  test = /rgb\(\s*([0-9]{1,3})\s*,\s*([0-9]{1,3})\s*,\s*([0-9]{1,3})\s*\)/.exec(
    color
  );
  if (test) {
    return [parseInt(test[1]), parseInt(test[2]), parseInt(test[3])];
  }

  test =
    /rgb\(\s*([0-9]+(?:\.[0-9]+)?)%\s*,\s*([0-9]+(?:\.[0-9]+)?)%\s*,\s*([0-9]+(?:\.[0-9]+)?)%\s*\)/.exec(
      color
    );
  if (test) {
    return [
      parseFloat(test[1]) * 2.55,
      parseFloat(test[2]) * 2.55,
      parseFloat(test[3]) * 2.55,
    ];
  }

  test = /#([a-fA-F0-9]{2})([a-fA-F0-9]{2})([a-fA-F0-9]{2})/.exec(color);
  if (test) {
    return [
      parseInt(test[1], 16),
      parseInt(test[2], 16),
      parseInt(test[3], 16),
    ];
  }

  test = /#([a-fA-F0-9])([a-fA-F0-9])([a-fA-F0-9])/.exec(color);
  if (test) {
    return [
      parseInt(test[1] + test[1], 16),
      parseInt(test[2] + test[2], 16),
      parseInt(test[3] + test[3], 16),
    ];
  }

  return [0, 0, 0];
};

export const getLuminance = (color: string): number => {
  let [red, green, blue] = getRGB(color);

  if (!red || !green || !blue) {
    return 0;
  }

  return 0.2126 * red + 0.7152 * green + 0.0722 * blue;
};

export const isLight = (color: string): boolean => {
  return getLuminance(color) < 160;
};

export const getContrastColor = (color: string): string => {
  return isLight(color) ? lightColor : darkColor;
};
