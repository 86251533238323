import React, { FunctionComponent } from "react";
import { CTADemo } from "components/ui/extended/DemoCTA/index.style";
import Icon from "components/core/Icon";
import { useTranslation } from "react-i18next";
import SafeLink from "components/core/SafeLink";
import { DEMO_PATH } from "constants/paths";

const DemoCTA: FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <CTADemo as={SafeLink} to={DEMO_PATH}>
      {t("components.ui.extended.DemoCTA.viewDemo")}
      <Icon size={12} type={"forward"} />
    </CTADemo>
  );
};

export default DemoCTA;
