import Cookies from "universal-cookie";
import { setCookie } from "utils/cookie";
import { TRACKING_ELEMENTS } from "constants/tracking";
import { SIGNUP_URL } from "constants/paths";
import queryString from "query-string";
import { slugify } from "utils/string";
import { Tracker, TrackingProps } from "app-types/tracking";
import { Currency } from "app-types/currency";
import { Utm } from "react-calendly/typings/calendly";

const isNotEmpty = (value: string | string[] | null): boolean => {
  return value !== null && value !== undefined && value !== "";
};

const setTracking = (props: TrackingProps): void => {
  const currentTracking = getTracking();

  Object.keys(props).forEach((tracker) => {
    const isSet = !!currentTracking[tracker];
    if (isNotEmpty(props[tracker] as Tracker) && !isSet) {
      setCookie(tracker, props[tracker] as string);
    }
  });
};

const getTracking = (): TrackingProps => {
  const cookies = new Cookies(document.cookie);
  const rawTracking: TrackingProps = {};

  TRACKING_ELEMENTS.forEach((cookie) => {
    const cookieContent = cookies.get(cookie);
    if (isNotEmpty(cookieContent)) {
      rawTracking[cookie] = cookieContent;
    }
  });

  return rawTracking;
};

const getUtmFromCookies = () => {
  if (typeof document !== "undefined") {
    const cookies = new Cookies(document.cookie);

    return {
      utmCampaign: cookies.get("utm_campaign"),
      utmSource: cookies.get("utm_source"),
      utmMedium: cookies.get("utm_medium"),
      utmContent: cookies.get("utm_content"),
      utmTerm: cookies.get("utm_term"),
    } as Utm;
  }
};

const getTrackedRegistrationUrl = ({
  cta,
  page,
  plan,
  activeMAU,
  frequencyMonths,
  emailsPerMonth,
  interactionsEstimate,
  teammates,
  currency,
}: {
  cta: string;
  page: string;
  plan: string;
  activeMAU?: number;
  frequencyMonths: number;
  emailsPerMonth?: number;
  interactionsEstimate?: number;
  teammates?: number;
  currency: Currency;
}): string => {
  const searchString = queryString.stringify({
    cta: slugify(cta),
    page,
    plan,
    activeMAU,
    frequencyMonths,
    emailsPerMonth,
    interactionsEstimate,
    teammates,
    currency,
  });

  return `${SIGNUP_URL}?${searchString}`;
};

export {
  getTracking,
  setTracking,
  getTrackedRegistrationUrl,
  getUtmFromCookies,
};
