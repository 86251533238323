import styled from "styled-components";
import { StyledButton } from "components/core/Button/index.style";

const CTADemo = styled(StyledButton)`
  background: ${(props) => props.theme.whiteColor};
  border-color: ${(props) => props.theme.titleColor};
  color: ${(props) => props.theme.titleColor};
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-flow: row nowrap;
  font-size: 16px;
  font-weight: 400;
  transition: all 0.3s ease-in-out;
  height: 48px;

  svg {
    stroke: transparent;
    opacity: 0;
    transform: scale(0);
    width: 0;
  }

  &:hover {
    cursor: pointer;
    text-decoration: none !important;

    background: ${(props) => props.theme.titleColor};
    border-color: ${(props) => props.theme.titleColor};
    color: ${(props) => props.theme.whiteColor};

    transition: all 0.3s ease-in-out;

    svg {
      stroke: ${(props) => props.theme.whiteColor};
      opacity: 1;
      transform: scale(1);
      width: 26px;
    }
  }
`;

export { CTADemo };
