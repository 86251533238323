import styled, { css } from "styled-components";
import { InternalHeadingProps } from "components/core/Typography/Heading";

const StyledHeading = styled.h1<InternalHeadingProps>`
  font-weight: ${(props) => props.weight};

  ${(props) =>
    props.maxWidth &&
    `
      max-width: ${props.maxWidth}px;
    `}

  ${(props) =>
    props.titleColor &&
    css`
      color: ${props.theme.titleColor};
    `}
`;

export { StyledHeading };
