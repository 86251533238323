import React from "react";
import { useTranslation } from "react-i18next";
import { GetStartedButton, StyledRegisterCTA } from "./index.style";
import { SIGNUP_URL } from "constants/paths";
import SafeLink from "components/core/SafeLink";
import Icon from "components/core/Icon";

export interface RegisterCTAProps {
  className?: string;
}

const RegisterCTA = (props: RegisterCTAProps) => {
  const { t } = useTranslation();

  return (
    <StyledRegisterCTA className={props.className}>
      <GetStartedButton as={SafeLink} to={SIGNUP_URL}>
        {t("components.ui.extended.RegisterCTA.getStarted")}
        <Icon size={12} type={"arrow-right"} />
      </GetStartedButton>
    </StyledRegisterCTA>
  );
};

export default RegisterCTA;
